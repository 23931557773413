import React, { useState, useEffect } from 'react';
import tinycolor from 'tinycolor2';

function isColorDarkOrLight(color) {
    let r, g, b;
    console.log(color)
    if (color.match(/^#([A-Fa-f0-9]{3}){1,2}$/)) {
        let c = color.substring(1).split('');
        if (c.length === 3) {
            c = [c[0], c[0], c[1], c[1], c[2], c[2]];
        }
        c = '0x' + c.join('');
        r = (c >> 16) & 255;
        g = (c >> 8) & 255;
        b = c & 255;
    } else if (color.startsWith('rgb')) {
        const values = color.match(/\d+/g);
        r = parseInt(values[0]);
        g = parseInt(values[1]);
        b = parseInt(values[2]);
    } else {
        return null;
    }

    let tcolor = tinycolor({ r: r, g: g, b: b });
    console.log(tcolor.getLuminance())
    // Using the HSP value, determine whether the color is light or dark
    return tcolor.isLight() ? 'light' : 'dark';
    //   const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
    //   return luminance > 0.5 ? 'light' : 'dark';
}

function getAverageColorOfImage(image, callback) {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = image.width;
    canvas.height = image.height;

    try {
        context.drawImage(image, 0, 0, canvas.width, canvas.height);

        const data = context.getImageData(0, 0, canvas.width, canvas.height).data;
        let r = 0, g = 0, b = 0;

        for (let i = 0; i < data.length; i += 4) {
            r += data[i];
            g += data[i + 1];
            b += data[i + 2];
        }

        r = Math.floor(r / (data.length / 4));
        g = Math.floor(g / (data.length / 4));
        b = Math.floor(b / (data.length / 4));

        const color = tinycolor({ r: r, g: g, b: b });
        const luminance = (0.299 * r + 0.587 * g + 0.114 * b) / 255;
        callback(color.isLight() ? 'light' : 'dark');
    } catch (error) {
        console.error('CORS prevents image processing.', error);
        callback('default');  // Fallback in case of CORS issue
    }
}

// Custom hook to check background
export function useBackgroundChecker() {
    const [bgType, setBgType] = useState('');

    useEffect(() => {
        function checkBackground() {
            // Get the element at the center of the window
            const element = document.elementFromPoint(window.innerWidth / 2, window.innerHeight / 2);
            const computedStyle = window.getComputedStyle(element);

            // Check if the element is an image tag
            if (element.tagName.toLowerCase() === 'img') {
                processImage(element.src); // Process image from img tag
                return;
            }

            // Check for background image in a div (like swiper-slide)
            const bgImage = computedStyle.backgroundImage;
            if (bgImage && bgImage !== 'none') {
                const url = bgImage.match(/url\(["']?([^"']*)["']?\)/)[1];
                processImage(url); // Process background image
                return;
            }

            // If neither, fallback to background color check
            const bgColor = computedStyle.backgroundColor;
            if (bgColor && bgColor !== 'rgba(0, 0, 0, 0)') { // Skip transparent
                const result = isColorDarkOrLight(bgColor);
                if (result) {
                    setBgType(result);
                    return;
                }
            }
        }

        function processImage(imageSrc) {
            const image = new Image();
            image.crossOrigin = "Anonymous"; // Try to bypass CORS issue for external images
            image.src = imageSrc;

            image.onload = () => {
                try {
                    getAverageColorOfImage(image, (result) => {
                        setBgType(result);
                    });
                } catch (error) {
                    console.error('Unable to process image due to CORS policy.', error);
                    setBgType('default');  // Handle accordingly, e.g., fallback color or type
                }
            };

            image.onerror = () => {
                console.error('Failed to load image.');
                setBgType('default');  // Handle loading error
            };
        }



        checkBackground(); // Initial check

        const handleScroll = () => {
            checkBackground();
        };

        window.addEventListener('scroll', handleScroll);

        // Run checkBackground every 5 seconds
        const intervalId = setInterval(() => {
            checkBackground();
        }, 5000);

        // Cleanup: remove scroll listener and interval when necessary
        return () => {
            window.removeEventListener('scroll', handleScroll);
            clearInterval(intervalId); // Clear interval when component unmounts or necessary
        };
    }, []);

    return bgType;
}
